import React from "react"
import {
  InfoMessageWrapper,
  InfoMessageContent,
  InfoMessageText,
  InfoMessageIcon,
  Column,
} from "./style"
import { SmallText } from "../Card"
import CampaignIcon from "./campaign.svg"

const InfoMessage = () => {
  const [message, setMessage] = React.useState({
    Active: false,
    Message: "",
    Label: "",
  })

  React.useEffect(function () {
    fetch("https://admin.luo.no/api/announcement/")
      .then(response => response.json())
      .then(({ data }) => {
        const attributes = data.attributes;
        setMessage(attributes)
      })
  }, [])
  return message.Active ? (
    <InfoMessageWrapper>
      <InfoMessageContent>
        <InfoMessageIcon src={CampaignIcon} />
        <Column>
          <SmallText>{message.Label}</SmallText>
          <InfoMessageText>{message.Message}</InfoMessageText>
        </Column>
      </InfoMessageContent>
    </InfoMessageWrapper>
  ) : null
}

export default InfoMessage
