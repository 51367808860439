import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

/**
 * 
const TOKEN =
  "IGQVJVUDVIWTA3dWpqLUpQU1pudU1kVXdIeUVESWpVOFduWW9mRFJIRVMxR2JKT2pLem5oUFA2RDgzcUh4S250WTI2WWpoVlBLdHJWSnQ0eWtjT3NRX20tTzktdGRYNF9BbElxQk1qblRNUXZANcmxIUAZDZD"

const URL = `https://graph.instagram.com/me/media?fields=id,media_type,caption,media_url,username,timestamp
&access_token=${TOKEN}`
 */
const ImageWrapper = styled.div`
  width: 100%;
`

const Instagram = () => {
  const [ref, inView] = useInView({ threshold: 0, rootMargin: "10px" })
  React.useEffect(() => {
    if (inView) {
      const s = document.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.innerHTML =
        '(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"));'
      if (window?.document?.body) {
        window.document.body.appendChild(s)
      }
    }
  }, [inView])
  return (
    <ImageWrapper ref={ref}>
      <div
        className="embedsocial-hashtag"
        data-ref="98f602a0dac7a617de74143c04f1b8673bcea929"
      ></div>
    </ImageWrapper>
  )
}
export default Instagram
