import React from "react"
import styled from "styled-components"
import { updateGTM } from "./globals"
import { Link } from "gatsby"

const Card = styled.div`
  border-radius: 10px;
  background: ${props => (props.dark ? "#0B0C14" : "#494A57")};
  padding: ${props => (props.dark ? 0 : 1.5)}rem;
  margin: auto;
  max-width: 750px;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2em;
  @media screen and (max-width: 991px) {
    margin: 1em auto;
    border-radius: 10px 10px 0 0;
  }
`
export const SmallText = styled.h5`
  font-weight: 500;
  color: ${({ color }) => color ?? "white"};
  letter-spacing: 1.3px;
  margin: 0;
  font-size: 0.7rem;
  margin-bottom: 0.5em;
`
const Text = styled.h3`
  color: white;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 1.3;
  margin: 0;
  font-size: 1.8rem;
  @media screen and (max-width: 991px) {
    font-size: 1.5rem;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  margin-top: auto;
`
const Button = styled(Link)`
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1.2px;
  background: ${props => (props.light ? "transparent" : "#405DE6")};
  color: ${props => (props.light ? "#8AB1E6" : "#ffffff")};
  flex: ${props => (props.light ? 1 : 2)};
  @media screen and (max-width: 991px) {
    flex: 1;
  }
`
const CardRow = styled.div`
  display: flex;
  flex-direction: row;
`
const Column = styled.div`
  display: block;
  flex: ${props => (props.image ? 1 : 3)};
  padding: ${props => (props.image ? 0 : 1.5)}em;
  ${props =>
    props.flex
      ? `
        display: flex;
        flex-direction: column;
    `
      : null}
  @media screen and (max-width:991px) {
    display: ${props => (props.image ? "none" : "block")};
  }
`

const StyledCard = styled(Card)`
  @media screen and (max-width: 800px) {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
`
export const ContentCard = ({ pretext, text }) => {
  return (
    <Card>
      <SmallText>{pretext}</SmallText>
      <Text>{text}</Text>
    </Card>
  )
}
export const HeroCard = ({ pretext, text }) => (
  <StyledCard dark>
    <CardRow>
      <Column flex>
        <SmallText>{pretext}</SmallText>
        <Text>{text}</Text>
        <Row>
          <Button to="/timebestilling/" onClick={updateGTM("bestillTimeKlikk")}>
            Bestill time
          </Button>
          <Button light to="/linser/" onClick={updateGTM("bestillLinserKlikk")}>
            Bestill linser
          </Button>
        </Row>
      </Column>
    </CardRow>
  </StyledCard>
)
