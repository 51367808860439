import React from "react"
import styled from "styled-components"

const SVG = styled.svg`
  width: 90%;
  position: absolute;
  z-index: 2;
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 500px;
  top: 15%;
  filter: drop-shadow(0 0px 10px rgba(30, 29, 34, 0.5));
  & * {
    fill: #fff;
  }
`

const LOGO = () => (
  <>
    <SVG
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 150"
    >
      <title>Lambertseter UR & Optikk</title>
      <g>
        <path d="M23.62,60.15V18.27h9.12V52.05h16v8.1Z" />
        <path d="M82.24,60.15l-3-10h-14l-3,10H53l13.8-41.88H78.1L91.72,60.15Zm-9.9-33.6H72l-4.62,15.9H77Z" />
        <path d="M128.86,32.67h-.18l-3.06,6.12-8.16,14.82-8-14.76-3.18-6.6h-.18v27.9H97.54V18.27h9.66l10.26,19.62h.12l10.14-19.62h9.72V60.15h-8.58Z" />
        <path d="M146.68,18.27H167q5.22,0,8.13,2.94a10.6,10.6,0,0,1,2.91,7.8,11.66,11.66,0,0,1-.63,4.08,8,8,0,0,1-1.68,2.76,6.45,6.45,0,0,1-2.46,1.59,9.94,9.94,0,0,1-3,.57v.36a10.17,10.17,0,0,1,3.15.54,9,9,0,0,1,3,1.71,9.58,9.58,0,0,1,2.31,3,10.12,10.12,0,0,1,.93,4.56,13.3,13.3,0,0,1-.81,4.65,11.86,11.86,0,0,1-2.25,3.81,10.5,10.5,0,0,1-3.42,2.55,10,10,0,0,1-4.32.93h-22.2Zm9.12,17.16h9.06a3.53,3.53,0,0,0,3.84-3.84v-1.8A3.53,3.53,0,0,0,164.86,26H155.8Zm0,17h10.5a4,4,0,0,0,2.85-1,3.65,3.65,0,0,0,1-2.79v-2a3.65,3.65,0,0,0-1-2.79,4,4,0,0,0-2.85-1H155.8Z" />
        <path d="M186.7,60.15V18.27h28.5v8.1H195.82v8.52h16.61V43H195.82v9.06H215.2v8.1Z" />
        <path d="M232.23,60.15h-9.12V18.27H243a13.63,13.63,0,0,1,5.22,1,11.3,11.3,0,0,1,4,2.73,12.31,12.31,0,0,1,2.54,4.23,15.79,15.79,0,0,1,.9,5.46,14.29,14.29,0,0,1-1.89,7.44A10.82,10.82,0,0,1,248,43.71l8.22,16.44H246.09l-7.32-15.36h-6.54Zm9.6-23a4.63,4.63,0,0,0,3.21-1A3.94,3.94,0,0,0,246.21,33V30.33A3.94,3.94,0,0,0,245,27.24a4.63,4.63,0,0,0-3.21-1h-9.6V37.11Z" />
        <path d="M279.58,26.37V60.15h-9.12V26.37H259.12v-8.1h31.79v8.1Z" />
        <path d="M310,60.87A21,21,0,0,1,300.64,59a21.6,21.6,0,0,1-6.54-4.9l6-6.07a13.54,13.54,0,0,0,10.5,4.76,7.63,7.63,0,0,0,5-1.37,4.51,4.51,0,0,0,1.62-3.63,4.41,4.41,0,0,0-1-2.88c-.65-.77-1.95-1.3-3.91-1.58l-4.13-.53c-4.44-.56-7.7-1.9-9.75-4a11.59,11.59,0,0,1-3.09-8.47,12.16,12.16,0,0,1,1.08-5.16,11.25,11.25,0,0,1,3.09-4,14.32,14.32,0,0,1,4.92-2.61,24,24,0,0,1,15,.57,16.88,16.88,0,0,1,6.19,4.39l-6.06,6.13a12.06,12.06,0,0,0-3.63-2.82,11.56,11.56,0,0,0-5.37-1.1A7.81,7.81,0,0,0,306,26.72a3.43,3.43,0,0,0-1.5,3,3.75,3.75,0,0,0,1.17,3.09,8.06,8.06,0,0,0,3.81,1.37l4.14.66c4.31.68,7.52,2,9.6,4s3.12,4.8,3.12,8.44a13.88,13.88,0,0,1-1.08,5.53,11.74,11.74,0,0,1-3.16,4.32,14.25,14.25,0,0,1-5.12,2.8A22.67,22.67,0,0,1,310,60.87Z" />
        <path d="M333.87,60.15V18.27h28.5v8.1H343v8.52h16.62V43H343v9.06h19.37v8.1Z" />
        <path d="M387.75,26.37V60.15h-9.12V26.37H367.29v-8.1h31.8v8.1Z" />
        <path d="M405.33,60.15V18.27h28.5v8.1H414.46v8.52h16.62V43H414.46v9.06h19.37v8.1Z" />
        <path d="M450.87,60.15h-9.12V18.27h19.87a13.58,13.58,0,0,1,5.21,1,11.3,11.3,0,0,1,4,2.73,12.47,12.47,0,0,1,2.54,4.23,15.79,15.79,0,0,1,.9,5.46,14.29,14.29,0,0,1-1.89,7.44,10.82,10.82,0,0,1-5.73,4.62l8.22,16.44H464.73l-7.32-15.36h-6.54Zm9.6-23a4.63,4.63,0,0,0,3.21-1A3.94,3.94,0,0,0,464.85,33V30.33a3.94,3.94,0,0,0-1.17-3.09,4.63,4.63,0,0,0-3.21-1h-9.6V37.11Z" />
        <path d="M32.8,90.27V116q0,4.44,1.74,6.63t5.82,2.19q4.08,0,5.82-2.19T47.92,116V90.27H56.8V115a32.5,32.5,0,0,1-.87,8,13,13,0,0,1-2.85,5.58,11.79,11.79,0,0,1-5.1,3.24,24.08,24.08,0,0,1-7.62,1.05,24.08,24.08,0,0,1-7.62-1.05,11.79,11.79,0,0,1-5.1-3.24A13,13,0,0,1,24.79,123a32.5,32.5,0,0,1-.87-8V90.27Z" />
        <path d="M82.66,132.15H73.54V90.27H93.4a13.63,13.63,0,0,1,5.22,1,11.18,11.18,0,0,1,4,2.73,12.34,12.34,0,0,1,2.55,4.23,15.79,15.79,0,0,1,.9,5.46,14.29,14.29,0,0,1-1.89,7.44,10.82,10.82,0,0,1-5.73,4.62l8.22,16.44H96.52L89.2,116.79H82.66Zm9.6-23a4.63,4.63,0,0,0,3.21-1A3.94,3.94,0,0,0,96.64,105v-2.64a3.94,3.94,0,0,0-1.17-3.09,4.63,4.63,0,0,0-3.21-1h-9.6v10.92Z" />
        <path d="M168.22,128.73a21.19,21.19,0,0,1-4.8,2.88,17.74,17.74,0,0,1-7.2,1.26,19.56,19.56,0,0,1-6.12-.87,12.54,12.54,0,0,1-4.44-2.46,10.55,10.55,0,0,1-2.73-3.72,11.49,11.49,0,0,1-.93-4.65,10.89,10.89,0,0,1,.63-3.84,11,11,0,0,1,1.74-3.06,14,14,0,0,1,2.67-2.52q1.56-1.14,3.36-2.22a23.1,23.1,0,0,1-1.74-2.1,18.55,18.55,0,0,1-1.5-2.4,13.71,13.71,0,0,1-1-2.64,10.32,10.32,0,0,1-.39-2.82,8.9,8.9,0,0,1,.87-3.87,9.45,9.45,0,0,1,2.52-3.21,12.16,12.16,0,0,1,4-2.16,16.17,16.17,0,0,1,5.19-.78,17.2,17.2,0,0,1,4.77.63A11.48,11.48,0,0,1,166.84,92a8.87,8.87,0,0,1,2.55,3,8.76,8.76,0,0,1,.93,4.08,9.46,9.46,0,0,1-.66,3.6,11,11,0,0,1-1.8,3,15.93,15.93,0,0,1-2.67,2.49q-1.53,1.14-3.21,2.16l6.48,7a21.94,21.94,0,0,0,1.2-4.08,31.82,31.82,0,0,0,.54-4.26h10v7.38h-3.78a26,26,0,0,1-2.7,6.66l8.34,9.06H171.34Zm-10.68-3.24a10.67,10.67,0,0,0,6.06-1.68l-8.82-9.54a8.68,8.68,0,0,0-2.88,2.46,5.07,5.07,0,0,0-.84,2.88v.6a4.93,4.93,0,0,0,1.62,3.78A6.93,6.93,0,0,0,157.54,125.49ZM158,96.33a4.19,4.19,0,0,0-2.73.9,3.06,3.06,0,0,0-1.11,2.52V100a4.24,4.24,0,0,0,.78,2.37,33.56,33.56,0,0,0,2.64,3.27,12.53,12.53,0,0,0,3.18-2.55,4.66,4.66,0,0,0,1.08-3.09v-.24a3.06,3.06,0,0,0-1.11-2.52A4.19,4.19,0,0,0,158,96.33Z" />
        <path d="M233.32,132.87a20,20,0,0,1-7.56-1.38,15.22,15.22,0,0,1-5.82-4.11,19.07,19.07,0,0,1-3.72-6.78,34.06,34.06,0,0,1,0-18.78A19.07,19.07,0,0,1,219.94,95a15.22,15.22,0,0,1,5.82-4.11,21.4,21.4,0,0,1,15.12,0A15.22,15.22,0,0,1,246.7,95a19.23,19.23,0,0,1,3.72,6.78,34.06,34.06,0,0,1,0,18.78,19.23,19.23,0,0,1-3.72,6.78,15.22,15.22,0,0,1-5.82,4.11A20,20,0,0,1,233.32,132.87Zm0-8.1a8,8,0,0,0,6.45-2.7q2.31-2.7,2.31-7.74v-6.24q0-5-2.31-7.74a9.05,9.05,0,0,0-12.9,0q-2.31,2.7-2.31,7.74v6.24q0,5,2.31,7.74A8,8,0,0,0,233.32,124.77Z" />
        <path d="M267.1,132.15V90.27h19.8a13.47,13.47,0,0,1,5.28,1,11.71,11.71,0,0,1,4,2.73,12,12,0,0,1,2.55,4.23,16.92,16.92,0,0,1,0,10.89,12,12,0,0,1-2.55,4.2,11.71,11.71,0,0,1-4,2.73,13.47,13.47,0,0,1-5.28,1H276.22v15.12Zm9.12-23h9.6a4.65,4.65,0,0,0,3.21-1A3.94,3.94,0,0,0,290.2,105v-2.64A3.94,3.94,0,0,0,289,99.24a4.65,4.65,0,0,0-3.21-1h-9.6Z" />
        <path d="M331.65,98.37v33.78h-9.12V98.37H311.19v-8.1H343v8.1Z" />
        <path d="M355.11,132.15v-7.26h5.4V97.53h-5.4V90.27H375v7.26h-5.4v27.36H375v7.26Z" />
        <path d="M405,113.91l-5.46,6.6v11.64h-9.12V90.27h9.12v19.92h.36l5.94-8.1,9.42-11.82h10.32l-14.1,17.22,15.36,24.66H416.07Z" />
        <path d="M454.47,113.91l-5.46,6.6v11.64h-9.12V90.27H449v19.92h.36l5.94-8.1,9.42-11.82h10.32L461,107.49l15.36,24.66H465.57Z" />
      </g>
    </SVG>
  </>
)
export default LOGO
