import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import { HeroCard } from "../components/Card"
import styled from "styled-components"
import LOGO from "../components/Logo"
import HeroSection, { HeroLine } from "../components/HeroSection"
import Instagram from "../components/Instagram"

const Hero = styled.div`
  height: 100%;
  height: ${props => (props.height ? `${props.height * 100}px` : "100%")};
  position: relative;
  @media screen and (min-width: 800px) {
    max-height: 80vh;
    max-width: 1280px;
    margin: 0 auto;
  }
`
// (Img)
const HeroBackground = styled(GatsbyImage)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  min-height: 90vh;
  object-fit: cover;
`

const IndexPage = () => {
  const [height, setHeight] = useState()
  const { heroImg, allStrapiGeneral } = useStaticQuery(
    graphql`query getHero {
  heroImg: file(relativePath: {eq: "hero.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
      }
  }
  allStrapiGeneral(limit: 1) {
    edges {
      node {
        address
        contactemail
        phonenumber
        Landingpagesection {
          SectionContent
          SectionImage {
              localFile{
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
          }
          Sectiontitle
        }
        weekdayopentimes
        weekendopentimes
      }
    }
  }
}
`
  )
  const {
    address,
    Landingpagesection,
    weekdayopentimes,
    weekendopentimes,
    phonenumber,
    contactemail,
  } = allStrapiGeneral.edges[0].node
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setHeight(window.innerHeight * 0.01)
    }
  }, [])
  return (
    <Layout
      title="Hjem"
      keywords={[
        "optiker",
        "Nordstrand",
        "Lambertseter",
        "briller",
        "linser",
        "klokker",
      ]}
    >
      <Hero height={height}>
        <LOGO />
        <HeroBackground image={heroImg?.childImageSharp?.gatsbyImageData} />
        <HeroCard
          pretext="Tid for en synsundersøkelse?"
          text="Bestill time raskt og enkelt!"
        />
      </Hero>
      <HeroLine
        phonenumber={phonenumber}
        contactemail={contactemail}
        manFre={weekdayopentimes}
        sat={weekendopentimes}
        action="_blank"
        address={address}
      />
      {Landingpagesection.map(
        ({ SectionContent, SectionImage, Sectiontitle }, i) => (
          <HeroSection
            reverse={i % 2 === 0}
            title={Sectiontitle}
            text={SectionContent}
            image={SectionImage}
          />
        )
      )}
      <Instagram />
    </Layout>
  );
}

export default IndexPage
