import styled from "styled-components"

const InfoMessageWrapper = styled.div`
  background-color: transparent;
  overflow: hidden;
`
const InfoMessageContent = styled.div`
  max-width: min(100vw, 750px);
  padding: 2em 0;
  background-color: #0b0c14;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`
const InfoMessageText = styled.p`
  color: white;
  margin: 0;
`
const InfoMessageIcon = styled.img`
  width: 2em;
  height: 2em;
  margin: 0 1em;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export {
  InfoMessageWrapper,
  InfoMessageContent,
  InfoMessageText,
  InfoMessageIcon,
  Column,
}
