import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import styled from "styled-components"
import { SmallText } from "../components/Card"
import { Column, SectionTitle } from "../components/globals"
import InfoMessage from "../components/InfoMessage"
import { updateGTM } from "./globals"

const SectionImage = styled(GatsbyImage)`
  position: relative;
  width: 90%;
  height: auto;
  max-height: 60vh;
  margin: auto;
`
const Section = styled.section`
  ${props => (props.first ? `padding-top:4em;` : null)}
  width:100%;
  height: 100%;
  display: flex;
  min-height: 95vh;
  flex-flow: ${props => (props.reverse ? "row-reverse" : "row")} wrap;
  @media screen and (min-width: 800px) {
    max-width: 1280px;
    margin: 0 auto;
  }
`
const SectionBox = styled.div`
  padding: 1em;
  margin: auto;
`
const SectionText = styled.p`
  letter-spacing: 1px;
  line-height: 1.6;
  font-size: 1.2em;
  opacity: 0.77;
  padding: 0 1em;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
`
const LineWrapper = styled.div`
  background: #0b0c14;
  width: 100%;
  margin: 3em 0;
  padding: 0 0 1em 0;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    display: block;
    z-index: 5;
    position: relative;
  }
  @media screen and (max-width: 991px) {
    margin: 0;
    border-radius: 0;
  }
`
const LineColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 180px;
  @media screen and (max-width: 320px) {
    margin: 1em 0;
  }
`
const ContentBox = styled.div`
  color: white;
  ${({ padding }) => (padding ? `padding:${padding};` : null)}
`
const MapButton = styled.a`
  background: white;
  border-radius: 3px;
  border: 0;
  padding: 0.5em 2em;
  color: #0b0c14;
  font-weight: 700;
  letter-spacing: 0.8px;
  cursor: pointer;
  text-decoration: none;
  line-height: 1em;
  @media screen and (max-width: 991px) {
    padding: 0.5em 1em;
  }
`
const Content = styled.h3`
  margin: 0;
  font-size: 1.2em;
  letter-spacing: 0.8px;
  ${({ thin }) => (thin ? "font-weight:300;" : null)}
`
const Icon = styled.svg`
  height: 1em;
  width: 1em;
  margin: 0 0.2em 0 0;
  fill: #0b0c14;
`
const OpenContent = styled.a`
  text-decoration: none;
  color: white;
`

export const HeroLine = ({
  manFre,
  sat,
  address,
  phonenumber,
  contactemail,
}) => (
  <LineWrapper>
    <InfoMessage />
    <Row>
      <LineColumn>
        <ContentBox padding="2em 0 0 0">
          <SmallText>Åpningstider:</SmallText>
          <Content>MAN-FRE: {manFre}</Content>
          <Content>LØR: {sat}</Content>
        </ContentBox>
      </LineColumn>
      <LineColumn>
        <MapButton
          onClick={updateGTM("veibeskrivelseKlikk")}
          rel="noreferrer"
          href={`https://maps.google.com/?q=${address}`}
          target="_blank"
        >
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#0B0C14"
              d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </Icon>
          Veibeskrivelse
        </MapButton>
      </LineColumn>
    </Row>
    <Row>
      <LineColumn>
        <ContentBox padding="2em 0 1em 0">
          <SmallText>Telefon:</SmallText>
          <Content>
            <OpenContent target="_blank" href={`tel:${phonenumber}`}>
              {phonenumber}
            </OpenContent>
          </Content>
        </ContentBox>
      </LineColumn>
      <LineColumn>
        <ContentBox padding="2em 0 0 0">
          <SmallText>Email:</SmallText>
          <Content>
            <OpenContent target="_blank" href={`mailto:${contactemail}`}>
              {contactemail}
            </OpenContent>
          </Content>
        </ContentBox>
      </LineColumn>
    </Row>
  </LineWrapper>
)

const HeroSection = ({ image, text, title, reverse }) => {
  return (
    <Section first reverse={reverse}>
      <Column>
        <SectionImage image={image?.localFile?.childImageSharp?.gatsbyImageData} />
      </Column>
      <Column>
        <SectionBox>
          <SectionTitle>{title}</SectionTitle>
          <SectionText>{text}</SectionText>
        </SectionBox>
      </Column>
    </Section>
  );
}
export default HeroSection
